@import url(https://fonts.googleapis.com/css?family=Nunito+Sans&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.shadow {
  box-shadow: 0px 14px 37px 0px rgba(0, 0, 0, 0.17);
}

.shadow-sm:hover {
  box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.17);
}

.shadow-right {
  box-shadow: 10px 0px 12px -8px rgba(0, 0, 0, 0.1);
}

.divmenuhover {
  padding: 20px;
}

.divmenuhover:hover {
  background-color: #e3f2fd;
  cursor: pointer;
}

.mybreadcrumb {
  cursor: pointer;
}

.mybreadcrumb:hover {
  color: #45a8e9;
}

.scroll-style {
  scroll-behavior: smooth;
  overflow-y: scroll;
}

.scroll-style:hover {
  scrollbar-width: thin;
}

.roundsearch .ant-input {
  border-radius: 25px;
}

.bg {
  background-image: url(/static/media/folder.e35eddca.svg);
  background-size: cover;
  background-repeat: no-repeat;
  -webkit-filter: drop-shadow(2px 2px 5px rgba(73, 54, 54, 0.2));
  filter: drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.2));
}

.mydropzone {
  padding: 50px;
  border-style: dashed;
  border-radius: 10px;
  border-width: 2px;
  border-color: red;
}

.ant-btn {
  box-shadow: 0 0px 0 rgba(0, 0, 0, 0.015);
}

.side-bg {
  background-image: url(/static/media/bg.2fbd6a3d.svg);
  background-repeat: no-repeat;
  background-size: cover;
}

.underline-input {
  background-color: transparent;
  border-width: 0px 0px 1px 0px;
  color: white;
}

.underline-input:hover {
  border-width: 0px;
  border-color: transparent;
}

.underline-input:focus {
  border-color: white;
  border-width: 1px;
}

.underline-input1 {
  background-color: transparent;
  border-width: 0px 0px 1px 0px;
}

.underline-input1:hover {
  border-width: 0px;
  border-color: transparent;
}

.underline-input1:focus {
  border-color: white;
  border-width: 1px;
}

.mid-section {
  display: flex;
  background-repeat: no-repeat;
  background-position: right;
  height: 75vh;
}

.mobile-button {
  height: 55px;
  margin-left: 27px;
  margin-top: 25px;
  margin-bottom: 25px;
  box-shadow: 0px 14px 37px 0px rgba(0, 0, 0, 0.17);
  display: flex;
  align-items: center;
  justify-content: left;
  font-size: 20px;
  font-weight: bold;
  border-radius: 10px;
  width: 72vw;
  padding-left: 20px;
}

.bottom-button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 55px;
  width: 72vw;
  font-size: 20px;
  font-weight: bold;
  background-color: white;
  color: #152a77;
  border-radius: 10px 0 0 10px;
  box-shadow: 0px 14px 37px 0px rgba(0, 0, 0, 0.17);
}

@-webkit-keyframes slideInFromRight {
  0% {
    -webkit-transform: translateX(200%);
            transform: translateX(200%);
  }
  100% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
}

@keyframes slideInFromRight {
  0% {
    -webkit-transform: translateX(200%);
            transform: translateX(200%);
  }
  100% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
}

.from-right {
  transition-timing-function: ease;
  /* Also the same as */
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px white;
  background-color: lightgray;
}

::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  background-color: #45a8e9;
}

::-webkit-scrollbar-thumb .scroll-management {
  width: 300px;
}
/*# sourceMappingURL=style.css.map */
